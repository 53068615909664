import { Component } from '@angular/core';
import { SharedService } from './shared/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Credential Viewer';
  serviceLoader: boolean = false;
  constructor(private sharedService: SharedService,private router: Router) { }
  ngAfterContentChecked() {
    this.serviceLoader = this.sharedService.loader;
  }  
}
