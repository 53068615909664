import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
    loader: boolean = false;

  constructor(private toastr: ToastrService) { }

  // **************** Method to start Loader ******************************
  startLoader() {
    this.loader = true;
  }
  // **************** Method to stop Loader ******************************
  stopLoader() {
    this.loader = false;
  }

  // **************** Method to send Toast message ******************************
  showToast(type: any, msg: any) {
    this.toastr.clear();
    if (type == 'success') {
      this.toastr.success(msg);
    } else if (type == 'error') {
      this.toastr.error(msg);
    } 
  }

}

