import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'credentialview',
    loadChildren: () => import('../app/credentials/credentials.module').then(m => m.CredentialsModule),
    // canActivate: [true]
    


  },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })  
export class AppRoutingModule { }
